export default class ClientProtocol {

	static PACKET_CREATE_SESSION = 0
	static PACKET_SUBMIT_PROMPT = 1
	static PACKET_DELETE_SESSION = 2
	static PACKET_GET_METADATA = 3
	static PACKET_SET_METADATA = 4
	static PACKET_DELETE_METADATA = 5
	static PACKET_SERVER_INFO = 6

	static CREATE_SESSION(uuid, geolocation = null) {
		const request = new DataView(new ArrayBuffer(1 + uuid.byteLength + 1 + 4 + 4))
		let offset = 0
		request.setUint8(offset++, ClientProtocol.PACKET_CREATE_SESSION)
		for (const byte of uuid) {
			request.setUint8(offset++, byte)
		}

		request.setUint8(offset++, geolocation === null ? 0 : 1)
		geolocation = geolocation === null ? [0, 0] : geolocation

		request.setFloat32(offset, geolocation[0])
		offset += 4

		request.setFloat32(offset, geolocation[1])
		offset += 4
		return request.buffer
	}

	static SUBMIT_PROMPT(uuid, prompt) {
		const promptBytes = new TextEncoder().encode(prompt)
		const request = new DataView(new ArrayBuffer(1 + uuid.byteLength + promptBytes.byteLength))
		let offset = 0
		request.setUint8(offset++, ClientProtocol.PACKET_SUBMIT_PROMPT)
		for (const byte of uuid) {
			request.setUint8(offset++, byte)
		}
		for (const byte of promptBytes) {
			request.setUint8(offset++, byte)
		}
		return request.buffer
	}

	static DELETE_SESSION(uuid) {
		const request = new DataView(new ArrayBuffer(1 + uuid.byteLength))
		let offset = 0
		request.setUint8(offset++, ClientProtocol.PACKET_DELETE_SESSION)
		for (const byte of uuid) {
			request.setUint8(offset++, byte)
		}
		return request.buffer
	}

	static GET_METADATA(uuid) {
		const request = new DataView(new ArrayBuffer(1 + uuid.byteLength))
		let offset = 0
		request.setUint8(offset++, ClientProtocol.PACKET_GET_METADATA)
		for (const byte of uuid) {
			request.setUint8(offset++, byte)
		}
		return request.buffer
	}

	static SET_METADATA(uuid, entries) {
		const entriesJsonBytes = new TextEncoder().encode(JSON.stringify(entries))

		const request = new DataView(new ArrayBuffer(1 + uuid.byteLength + entriesJsonBytes.byteLength))
		let offset = 0
		request.setUint8(offset++, ClientProtocol.PACKET_SET_METADATA)
		for (const byte of uuid) {
			request.setUint8(offset++, byte)
		}
		for (const byte of entriesJsonBytes) {
			request.setUint8(offset++, byte)
		}
		return request.buffer
	}

	static DELETE_METADATA(uuid, meta_key) {
		const metaKeyBytes = new TextEncoder().encode(meta_key)
		const request = new DataView(new ArrayBuffer(1 + uuid.byteLength + metaKeyBytes.byteLength))
		let offset = 0
		request.setUint8(offset++, ClientProtocol.PACKET_DELETE_METADATA)
		for (const byte of uuid) {
			request.setUint8(offset++, byte)
		}
		for (const byte of metaKeyBytes) {
			request.setUint8(offset++, byte)
		}
		return request.buffer
	}

	static SERVER_INFO() {
		const request = new DataView(new ArrayBuffer(1))
		request.setUint8(0, ClientProtocol.PACKET_SERVER_INFO)
		return request.buffer
	}
}