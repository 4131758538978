import { Component } from "react"
import { Button, Card, Container, Form, FormField, FormGroup, Grid, Header, Icon, Image, Input, Modal, Progress, Segment, Table, TableBody, TableCell, TableRow } from "semantic-ui-react"
import ClientProtocol from "./ClientProtocol"
import Markdown from 'react-markdown'
import { SemanticToastContainer, toast } from 'react-semantic-toasts';
import 'react-semantic-toasts/styles/react-semantic-alert.css';

export default class App extends Component {
	static DEFAULT_WEBSOCKET_ENDPOINT_PREFIX = "wss://"
	static DEFAULT_WEBSOCKET_ENDPOINT = "liyara.muqs.it"

	static SERVER_STATE_READY = 1000001
	static SERVER_STATE_COMPUTING_GENERIC = 1000002
	static SERVER_STATE_COMPUTING_RESPONSE_INITIATED = 1000003
	static SERVER_STATE_COMPUTING_RESPONSE_IN_PROGRESS = 1000004

	static SERVER_STATUS_CONNECTING = 100001
	static SERVER_STATUS_OFFLINE = 100002
	static SERVER_STATUS_ONLINE = 100003

	static DEFAULT_AVATAR = "steve.jpg"

	static DEFAULT_RUNTIME_STATE = {
		name: null,
		address_as: null,
		sex: null,
		metadata: null,
		avatar: null,
		conversation_state: null,
		view_profile: false,
		view_server_info: false,
		server_info: null,
		messages: [],
		server_state: App.SERVER_STATE_READY,
		server_status: App.SERVER_STATUS_CONNECTING,
		showing_remember_alert: 0
	}

	constructor(props) {
		super(props)

		const knownUuid = window.localStorage.getItem("uuid")
		let uuid
		if (knownUuid === null) {
			uuid = new Uint8Array(16)
			window.crypto.getRandomValues(uuid)
			window.localStorage.setItem("uuid", Array.from(uuid))
		} else {
			uuid = new Uint8Array(knownUuid.split(",").map(x => parseInt(x)))
		}

		const knownWebsocketEndpoint = window.localStorage.getItem("ws-endpoint")
		let websocketEndpoint
		if (knownWebsocketEndpoint === null) {
			websocketEndpoint = App.DEFAULT_WEBSOCKET_ENDPOINT
		} else {
			websocketEndpoint = knownWebsocketEndpoint
		}

		this.state = {
			uuid: uuid,
			websocket_endpoint: websocketEndpoint,
			websocket_endpoint_input_value: websocketEndpoint,
			window_height: window.innerHeight,
			screen_type: window.innerHeight > window.innerWidth ? "tall" : "wide",
			...App.DEFAULT_RUNTIME_STATE
		}
	}

	async reloadServerInfo(endpoint = null) {
		if (endpoint === null) {
			endpoint = this.state.websocket_endpoint
		}

		this.setState({ server_info: null })
		const result = await new Promise(resolve => {
			const websocket = new WebSocket(App.DEFAULT_WEBSOCKET_ENDPOINT_PREFIX + endpoint)
			websocket.onopen = () => { websocket.send(ClientProtocol.SERVER_INFO()) }
			websocket.onmessage = e => { resolve(JSON.parse(e.data)) }
			websocket.onerror = () => { resolve(false) }
			websocket.onclose = () => { }
		})
		this.setState({ server_info: result })
		return result
	}

	async createSession(uuid) {
		const send_request = new Promise((resolve, reject) => {
			const messages = []
			const websocket = new WebSocket(App.DEFAULT_WEBSOCKET_ENDPOINT_PREFIX + this.state.websocket_endpoint)
			websocket.onopen = async () => {
				let geolocation
				if (navigator.geolocation) {
					let position
					try {
						position = await new Promise((resolve, reject) => navigator.geolocation.getCurrentPosition(resolve, reject))
						geolocation = [position.coords.latitude, position.coords.longitude]
					} catch (e) {
						geolocation = null
					}
				} else {
					geolocation = null
				}
				try {
					await this.updateConversationState(uuid)
				} catch (e) {
					return
				}
				websocket.send(ClientProtocol.CREATE_SESSION(uuid, geolocation))
			}
			websocket.onmessage = event => { messages.push(JSON.parse(event.data)) }
			websocket.onerror = _ => { reject() }
			websocket.onclose = e => {
				if (e.wasClean) {
					resolve(messages)
				}
			}
		})

		this.setState({
			messages: [{ "role": "system", "content": <><Icon name="spinner" loading />Connecting...</>, "timestamp": Date.now() }],
			server_state: App.SERVER_STATE_COMPUTING_GENERIC,
			server_status: App.SERVER_STATUS_CONNECTING
		})

		try {
			const messages = await send_request
			await this.updateConversationState(uuid)
			this.setState({ messages: messages, server_status: App.SERVER_STATUS_ONLINE })
		} catch {
			this.setState({
				messages: [{ "role": "system", "content": <><Icon name="exclamation triangle" />The backend is offline. <a href="/">Reload page</a> and try again.</>, "timestamp": Date.now() }],
				server_status: App.SERVER_STATUS_OFFLINE
			})
		}
		this.setState({ server_state: App.SERVER_STATE_READY })
	}

	submitPrompt(uuid, prompt, message_index) {
		this.setState({server_state: App.SERVER_STATE_COMPUTING_RESPONSE_INITIATED})

		const websocket = new WebSocket(App.DEFAULT_WEBSOCKET_ENDPOINT_PREFIX + this.state.websocket_endpoint)
		websocket.onopen = () => {
			websocket.send(ClientProtocol.SUBMIT_PROMPT(uuid, prompt))
		}
		let messaging_started = false
		websocket.onmessage = async (event) => {
			if (event.data[0] === "\x00") {
				const token_type_len = event.data.charCodeAt(1)
				const token_type = event.data.slice(2, 2 + token_type_len)
				const token_value = JSON.parse(event.data.slice(2 + token_type_len))
				if (token_type === "END") {
					this.setState({ server_state: App.SERVER_STATE_READY })
					if (this.state.conversation_state !== "conversation") {
						await this.updateConversationState(uuid)
					}
				} else {
					let status = null
					let status_index = 0
					if (token_type === "QUEUED_FCALL") {
						let [position,] = token_value
						position--
						status = `Waiting for ${position} function${position === 1 ? "" : "s"}...`
						status_index = 1 + (1 / (1 + position))
					} else if (token_type === "PROCESS_FCALL") {
						status = `Processing function calls...`
						status_index = 2
					} else if (token_type === "QUEUED_CONVERSATION") {
						let [position,] = token_value
						position--
						status = `Waiting for ${position} conversation${position === 1 ? "" : "s"}...`
						status_index = 3 + (1 / (1 + position))
					} else if (token_type === "PROCESS_CONVERSATION") {
						status = `Processing conversation...`
						status_index = 4
					}
					if (status !== null) {
						const messages = this.state.messages
						messages[message_index]["content"] = <>
							<Progress percent={Math.floor((status_index / 4) * 100)} size='tiny' progress={status_index < 4} indicating>
								{status}
							</Progress>
						</>
						this.setState({ messages: messages })
					}
				}
			} else if (event.data[0] === "\x01") {
				const messages = this.state.messages
				if (!messaging_started) {
					messaging_started = true
					messages[message_index]["content"] = ""
					this.setState({ server_state: App.SERVER_STATE_COMPUTING_RESPONSE_IN_PROGRESS })
				}
				messages[message_index]["content"] += event.data.slice(1)
				this.setState({ messages: messages })
			} else {
				throw Error("Received unexpected event type")
			}
		}
	}

	deleteSession(uuid) {
		const websocket = new WebSocket(App.DEFAULT_WEBSOCKET_ENDPOINT_PREFIX + this.state.websocket_endpoint)
		websocket.onopen = () => {
			websocket.send(ClientProtocol.DELETE_SESSION(uuid))
			websocket.close()
			window.location.reload()
		}
		this.setState({
			uuid: uuid,
			name: null,
			address_as: null,
			sex: null,
			metadata: null,
			avatar: `/avatars/${App.DEFAULT_AVATAR}`,
			conversation_state: null,
		})
	}

	loadSessionMetadata(uuid) {
		return new Promise((resolve, reject) => {
			const websocket = new WebSocket(App.DEFAULT_WEBSOCKET_ENDPOINT_PREFIX + this.state.websocket_endpoint)
			websocket.onopen = () => {
				websocket.send(ClientProtocol.GET_METADATA(uuid))
			}
			websocket.onmessage = (event) => { resolve(JSON.parse(event.data)) }
			websocket.onerror = () => { reject() }
		});
	}

	setSessionMetadata(uuid, entries) {
		return new Promise((resolve, reject) => {
			const websocket = new WebSocket(App.DEFAULT_WEBSOCKET_ENDPOINT_PREFIX + this.state.websocket_endpoint)
			websocket.onopen = () => {
				websocket.send(ClientProtocol.SET_METADATA(uuid, entries))
				websocket.close()
			}
			websocket.onerror = () => { reject() }
			websocket.onclose = () => { resolve() }
		});
	}

	deleteSessionMetadata(uuid, meta_key) {
		return new Promise((resolve, reject) => {
			const websocket = new WebSocket(App.DEFAULT_WEBSOCKET_ENDPOINT_PREFIX + this.state.websocket_endpoint)
			websocket.onopen = () => {
				websocket.send(ClientProtocol.DELETE_METADATA(uuid, meta_key))
				websocket.close()

				const metadata = this.state.metadata
				delete metadata[meta_key]
				this.setState({ metadata: metadata })
			}
			websocket.onerror = () => { reject() }
			websocket.onclose = () => { resolve() }
		});
	}

	onSubmitPrompt(event, uuid) {
		if (this.state.server_state !== App.SERVER_STATE_READY) {
			return
		}

		const prompt = new FormData(event.target).get("prompt").trim()
		if (prompt.length === 0) {
			return
		}

		document.getElementById("prompt-field").value = ""

		const messages = this.state.messages
		messages.push({ "role": "user", "content": prompt, "timestamp": Date.now() })
		messages.push({ "role": "assistant", "content": <><Icon name="spinner" loading />Reading...</>, "timestamp": Date.now() })
		this.setState({ messages: messages, server_state: App.SERVER_STATE_COMPUTING_RESPONSE_INITIATED })

		setTimeout(() => this.submitPrompt(uuid, prompt, messages.length - 1), 100)
	}

	createChatEntry(message, key = null) {
		const alignment = message.role === "user" ? "right" : "left"

		const timestamp = ((timestamp) => {
			const messageDate = new Date(timestamp)
			const nowDate = new Date()

			let options = { hour: "numeric", minute: "numeric", hour12: true }
			if (nowDate.getDate() !== messageDate.getDate() || nowDate.getMonth() !== messageDate.getMonth() || nowDate.getFullYear() !== messageDate.getFullYear()) {
				options["day"] = "numeric"
				options["month"] = "short"
			}
			if (nowDate.getFullYear() !== messageDate.getFullYear()) {
				options["year"] = "numeric"
			}
			return messageDate.toLocaleTimeString("en-US", options);
		})(message.timestamp)

		let name
		let avatar
		const avatarProps = {
			size: this.state.screen_type === "wide" ? "tiny" : "mini",
			rounded: this.state.screen_type === "wide",
			circular: this.state.screen_type === "tall"
		}
		if (message.role === "assistant") {
			name = <b>Liyara</b>
			avatar = <Image src="/logo-profile.png" {...avatarProps} />
		} else if (message.role === "system") {
			name = <b>System</b>
			avatar = <Icon name="user" size="big" centered />
		} else if (message.role === "user") {
			name = [<b>{this.state.name === null ? "You" : this.state.name}</b>]
			if (this.state.address_as !== null && this.state.address_as !== undefined && this.state.address_as !== this.state.name) {
				name.push(<span style={{ color: "gray", fontSize: "12px" }}> (aka {this.state.address_as})</span>)
			}
			avatar = <Image src={this.state.avatar ?? `/avatars/${App.DEFAULT_AVATAR}`} {...avatarProps} />
		} else {
			throw new Error(`invalid role: ${message.role}`)
		}

		let avatarStyle = {
			paddingLeft: "0px",
			paddingRight: "0px",
			width: "0px"
		};
		const avatarEntry = <TableCell width={1} verticalAlign="top" centered style={avatarStyle}>{avatar}</TableCell>

		let row
		if (alignment === "left") {
			row = <TableRow key={0}>
				{avatarEntry}
				<TableCell verticalAlign="top">
					{name} <span style={{ color: "gray", fontSize: "12px" }}>{timestamp}</span>
					<br />
					<span style={{ fontWeight: "initial" }}>
						{typeof message.content === "string" ? <Markdown>{message.content}</Markdown> : message.content}
					</span>
				</TableCell>
			</TableRow>
		} else if (alignment === "right") {
			row = <TableRow key={0}>
				<TableCell textAlign="right" verticalAlign="top">
					<span style={{ color: "gray", fontSize: "12px" }}>{timestamp}</span> {name}<br />
					<span style={{ fontWeight: "initial" }}>{message.content}</span>
				</TableCell>
				{avatarEntry}
			</TableRow>
		} else {
			throw new Error(`invalid alignment type: ${alignment}`)
		}
		return <Table key={key} compact basic="very" style={{ fontSize: "16px" }}><TableBody>{row}</TableBody></Table>
	}

	async loadAvatars(sex = null) {
		const avatars = await fetch("/avatars/avatars.json")
		let avatars_all = await avatars.json()
		if (sex !== null) {
			avatars_all = avatars_all.filter(value => value["sex"] === sex)
		}
		const selected_index = this.state.avatar === null ? -1 : avatars_all.findIndex(value => `/avatars/${value["file"]}` === this.state.avatar)
		return [avatars_all, selected_index]
	}

	async updateConversationState(uuid) {
		const metadata = await this.loadSessionMetadata(uuid)
		if (("sex" in metadata) && this.state.avatar === null && !("avatar" in metadata)) {
			const [avatars,] = await this.loadAvatars(metadata["sex"])
			const avatar_selected = avatars.length > 0 ? avatars[Math.floor(Math.random() * avatars.length)] : avatars.find(value => value["file"] === App.DEFAULT_AVATAR)
			this.setAvatar(uuid, avatar_selected)
		}
		if ("avatar" in metadata) {
			if (this.state.avatar !== metadata["avatar"]) {
				this.setState({ avatar: metadata["avatar"] })
			}
		}
		if ("sex" in metadata && "avatar" in metadata) {
			const [avatars,] = await this.loadAvatars(metadata["sex"])
			const avatar_mismatch = avatars.findIndex(value => `/avatars/${value["file"]}` === metadata["avatar"]) === -1
			if (avatar_mismatch) {
				const avatar_selected = avatars[Math.floor(Math.random() * avatars.length)]
				this.setAvatar(uuid, avatar_selected)
			}
		}
		this.setState({ name: metadata["name"], address_as: metadata["address_as"], sex: metadata["sex"], metadata: metadata })
	}

	async setAvatar(uuid, avatar) {
		const avatar_path = `/avatars/${avatar["file"]}`
		await this.setSessionMetadata(uuid, { avatar: avatar_path })
		await this.updateConversationState(uuid)
	}

	renderChatFlow() {
		return this.state.messages.map((message, index) => {
			return this.createChatEntry(message, index)
		})
	}

	async componentDidMount() {
		await Promise.all([
			this.reloadServerInfo(),
			this.createSession(this.state.uuid)
		])

		window.addEventListener("resize", e => this.setState({
			window_height: e.target.innerHeight,
			screen_type: e.target.innerHeight > e.target.innerWidth ? "tall" : "wide"
		}))

		const element = document.getElementById("prompt-field")
		element.focus()
	}

	componentDidUpdate() {
		const chatWindow = document.getElementById("chat-window")
		chatWindow.scrollTop = chatWindow.scrollHeight

		const promptFieldButton = document.getElementById("prompt-field-button")
		if (this.state.server_state === App.SERVER_STATE_READY && this.state.server_status === App.SERVER_STATUS_ONLINE) {
			promptFieldButton.removeAttribute("loading")
			promptFieldButton.removeAttribute("disabled")
		} else {
			promptFieldButton.setAttribute("loading", "")
			promptFieldButton.setAttribute("disabled", "")
		}

		const uuid = this.state.uuid
		const alerts = this.state.metadata !== null && "alerts" in this.state.metadata ? JSON.parse(this.state.metadata["alerts"]) : null
		if (this.state.showing_remember_alert === 0) {
			if (alerts !== null) {
				this.setState({ showing_remember_alert: 1 })
			}
		} else if (this.state.showing_remember_alert === 1) {
			if (alerts !== null && alerts.length > 0) {
				this.setState({ showing_remember_alert: 2 })
				setTimeout(async () => {
					if (this.state.showing_remember_alert !== 2) {
						return
					}
					for (const alert of alerts) {
						toast({
							title: "Memory Updated",
							description: <p>{alert}</p>,
							type: "info",
							color: "green",
							icon: "info circle",
							time: 2750
						})
					}
					await this.deleteSessionMetadata(uuid, "alerts")
					this.setState({ showing_remember_alert: 0 })
				}, 100)
			} else {
				this.setState({ showing_remember_alert: 0 })
			}
		}
	}

	renderCardContent(widescreen) {
		const [server_status_color, server_status_message] = {
			[App.SERVER_STATUS_CONNECTING]: ["yellow", "Connecting..."],
			[App.SERVER_STATUS_OFFLINE]: ["red", "Offline"],
			[App.SERVER_STATUS_ONLINE]: ["green", "Online"]
		}[this.state.server_status]

		const heading = <>Liyara</>
		const subHeading = <>by Mohammed A. Muqsit</>
		const description = <>
			I am your personal assistant for all things Tangaliya related.
			Don't know what <i>Tangaliya</i> is? Ask me!
		</>

		const status = <>
			<span>
				<Icon name="circle" color={server_status_color} />
				{server_status_message}
			</span>
		</>

		const buttons = <>
			<Button icon="server" floated="right" size="mini" compact circular onClick={_ => this.setState({ view_server_info: true })} />
			<Button icon="download" floated="right" size="mini" compact circular disabled={this.state.server_status !== App.SERVER_STATUS_ONLINE || this.state.name === null} onClick={async e => {
				const now = new Date()
				const timestamp = now.toISOString().replace(/[:.]/g, "");
				const filename = `liyara-${now.getFullYear()}-${String(now.getMonth() + 1).padStart(2, "0")}-${String(now.getDate()).padStart(2, "0")}-${timestamp.slice(11, 17)}.json`;

				const content = {}
				content["metadata"] = this.state.metadata
				content["conversation"] = this.state.messages

				e.target.setAttribute("loading", "")
				e.target.setAttribute("disabled", "")
				try {
					const blob = new Blob([JSON.stringify(content)], { type: "application/json" });
					const link = document.createElement("a");
					link.href = URL.createObjectURL(blob);
					link.download = filename;
					document.body.appendChild(link);
					try {
						link.click();
					} finally {
						document.body.removeChild(link);
					}
				} finally {
					e.target.removeAttribute("loading")
					e.target.removeAttribute("disabled")
				}
			}} />
			<Button icon="user" floated="right" size="mini" compact circular disabled={this.state.server_status !== App.SERVER_STATUS_ONLINE || this.state.name === null} onClick={_ => this.setState({ view_profile: true })} />
		</>

		if (!widescreen) {
			return <Segment fluid>
				<Grid>
					<Grid.Column width={3}>
						<Image size="small" src="/logo.png" rounded />
					</Grid.Column>
					<Grid.Column width={13} floated="left">
						<Grid.Row>
							<Header>
								{heading}
								<Header.Subheader>
									{subHeading}
								</Header.Subheader>
							</Header>
							{description}
						</Grid.Row>
					</Grid.Column>
				</Grid>
				<hr />
				<Grid>
					<Grid.Column>
						<Grid.Row>
							{status}
							{buttons}
						</Grid.Row>
					</Grid.Column>
				</Grid>
			</Segment>
		}

		return <Card fluid raised>
			<Image src='/logo.png' wrapped size="big" />
			<Card.Content>
				<Card.Header>{heading}</Card.Header>
				<Card.Meta>
					{subHeading}
				</Card.Meta>
				<Card.Description>
					{description}
				</Card.Description>
			</Card.Content>
			<Card.Content extra>
				{status}
				{buttons}
			</Card.Content>
		</Card>
	}

	renderProfileModal() {
		if (this.state.name === null) {
			return <Modal open={false} key={this.state.metadata} />
		}
		return <Modal
			onClose={() => this.setState({ view_profile: false })}
			onOpen={() => this.setState({ view_profile: true })}
			open={this.state.view_profile}
			key={this.state.metadata}
		>
			<Modal.Header>Your Profile</Modal.Header>
			<Modal.Content>
				<Modal.Description>
					<Container fluid>
						<Segment compact attached="top">
							<Grid>
								<Grid.Column width={3}>
									<Image size="small" src={this.state.avatar} rounded />
								</Grid.Column>
								<Grid.Column width={13} floated="left">
									<Grid.Row>
										<Header>{this.state.name} <span>{this.state.address_as !== this.state.name ? ` (aka ${this.state.address_as})` : ""}</span></Header>
										We've found the following information associated with your profile.
										This information is used by Liyara to enhance your conversation experience with her.
										Don't like your avatar? Use the blue buttons to change your display picture.
										If you would like to delete all data associated with this conversation, click the red button below.
									</Grid.Row>
								</Grid.Column>
							</Grid>
						</Segment>
						<Button.Group compact attached="bottom">
							<Button icon="angle left" color="blue" title="Change Picture" onClick={async e => {
								e.target.setAttribute("loading", "")
								e.target.setAttribute("disabled", "")
								try {
									let [avatars, selected_index] = await this.loadAvatars(this.state.metadata["sex"])
									let new_index = selected_index - 1
									if (new_index < 0) {
										new_index += avatars.length
									}
									await this.setAvatar(this.state.uuid, avatars[new_index])
								} finally {
									e.target.removeAttribute("loading")
									e.target.removeAttribute("disabled")
								}
							}} />
							<Button icon="angle right" color="blue" title="Change Picture" onClick={async e => {
								e.target.setAttribute("loading", "")
								e.target.setAttribute("disabled", "")
								try {
									let [avatars, selected_index] = await this.loadAvatars(this.state.metadata["sex"])
									let new_index = selected_index + 1
									if (new_index >= avatars.length) {
										new_index -= avatars.length
									}
									await this.setAvatar(this.state.uuid, avatars[new_index])
								} finally {
									e.target.removeAttribute("loading")
									e.target.removeAttribute("disabled")
								}
							}} />
							<Button icon="trash" title="Delete Session" negative onClick={e => {
								this.setState({ view_profile: false })
								e.target.setAttribute("loading", "")
								e.target.setAttribute("disabled", "")
								try {
									this.deleteSession(this.state.uuid)
								} finally {
									e.target.removeAttribute("loading")
									e.target.removeAttribute("disabled")
								}
							}}>
							</Button>
						</Button.Group>
					</Container>
					<Table celled compact>
						<Table.Header>
							<Table.Row>
								<Table.HeaderCell>Attribute</Table.HeaderCell>
								<Table.HeaderCell>Value</Table.HeaderCell>
							</Table.Row>
						</Table.Header>
						<Table.Body>
							{Object.entries(this.state.metadata).map((value, index) => <Table.Row key={index}>
								<Table.Cell>{value[0]}</Table.Cell>
								<Table.Cell>{value[1]}</Table.Cell>
							</Table.Row>)}
						</Table.Body>
					</Table>
				</Modal.Description>
			</Modal.Content>
			<Modal.Actions>
				<Button color='black' onClick={() => this.setState({ view_profile: false })}>
					Okay
				</Button>
			</Modal.Actions>
		</Modal>
	}

	renderServerInfoModal() {
		let connectionStatus
		if (this.state.server_info === null) {
			connectionStatus = <>
				<Icon name="spinner" loading /> Connecting to the server
			</>
		} else if (this.state.server_info === false) {
			connectionStatus = <>
				<Icon name="exclamation triangle" /> Connection Failed
			</>
		} else {
			connectionStatus = <>
				<Icon name="dot circle" color="green" /> Connected.
				<Table celled compact>
					<Table.Header>
						<Table.Row>
							<Table.HeaderCell>Attribute</Table.HeaderCell>
							<Table.HeaderCell>Value</Table.HeaderCell>
						</Table.Row>
					</Table.Header>
					<Table.Body>
						<Table.Row key={-1}>
							<Table.Cell>Endpoint</Table.Cell>
							<Table.Cell>{App.DEFAULT_WEBSOCKET_ENDPOINT_PREFIX + this.state.websocket_endpoint}</Table.Cell>
						</Table.Row>
						{Object.entries(this.state.server_info).map((value, index) => <Table.Row key={index}>
							<Table.Cell>{value[0]}</Table.Cell>
							<Table.Cell>{value[1]}</Table.Cell>
						</Table.Row>)}
					</Table.Body>
				</Table>
			</>
		}

		return <Modal
			onClose={() => this.setState({ view_server_info: false })}
			onOpen={() => this.setState({ view_server_info: true })}
			open={this.state.view_server_info}
		>
			<Modal.Header>Liyara Server</Modal.Header>
			<Modal.Content>
				<Modal.Description>
					<Container fluid>
						<Segment compact attached="top">
							<Grid>
								<Grid.Column width={3}>
									<Image size="small" src="/logo.png" rounded />
								</Grid.Column>
								<Grid.Column width={13} floated="left">
									<Grid.Row>
										Liyara is by default hosted on my server. Want to host Liyara on your own device?
										Download the server files and follow the instructions in <a href="https://gist.github.com/Muqsit/3f38c5ff5fe5bcb16918dc8410c047fd?permalink_comment_id=5191036#gistcomment-5191036"><Icon name="github" />Muqsit/liyara.py</a>.
										Change the websocket URL to point to your Liyara server.
										<p>
											<b>NOTE: </b>As the website uses HTTPS, you may need to proxy your Liyara server through Cloudflare to create a WS-to-WSS proxy.
										</p>
										<Form onSubmit={async _ => {
											let value = this.state.websocket_endpoint_input_value.trim()
											if (value.length === 0) {
												value = App.DEFAULT_WEBSOCKET_ENDPOINT
											}
											window.localStorage.setItem("ws-endpoint", value)
											this.setState({ websocket_endpoint: value, websocket_endpoint_input_value: value, ...App.DEFAULT_RUNTIME_STATE, view_server_info: true })
											await Promise.all([this.reloadServerInfo(value), this.createSession(this.state.uuid)])
										}}>
											<Form.Group inline>
												<Form.Field width={13}>
													<Input label={App.DEFAULT_WEBSOCKET_ENDPOINT_PREFIX} type="text" placeholder={App.DEFAULT_WEBSOCKET_ENDPOINT} fluid value={this.state.websocket_endpoint_input_value} onChange={e => { this.setState({ websocket_endpoint_input_value: e.target.value }) }} />
												</Form.Field>
												<Form.Field width={3}>
													<Form.Button fluid color="blue">Update</Form.Button>
												</Form.Field>
											</Form.Group>
										</Form>
									</Grid.Row>
								</Grid.Column>
							</Grid>
						</Segment>
						<Header block attached="top">
							<Icon name="signal"></Icon>
							<Header.Content>
								Server Status
								<Header.Subheader>Connection status to the websocket server</Header.Subheader>
							</Header.Content>
						</Header>
						<Segment attached="bottom">
							{connectionStatus}
						</Segment>
					</Container>
				</Modal.Description>
			</Modal.Content>
			<Modal.Actions>
				<Button color='black' onClick={() => this.setState({ view_server_info: false })}>
					Okay
				</Button>
			</Modal.Actions>
		</Modal>
	}

	renderInformationText() {
		return <Grid stackable padded="horizontally" stretched>
			<Grid.Row divided stackable>
				<Grid.Column width={8}>
					<Header block attached="top">
						<Icon name="info circle"></Icon>
						<Header.Content>
							Why make Liyara?
							<Header.Subheader>The Purpose that Liyara Serves</Header.Subheader>
						</Header.Content>
					</Header>
					<Segment attached="bottom">
						<p>
							Tangaliya is a 700-year old handwoven textile made by the Dangasia community (<i>a scheduled caste in Gujarat, India</i>).
							But, it isn't just any ordinary textile, it is a <b>work of ART!</b> Sadly, customers as of recent have started preferring
							less coarse textures (i.e., ones with a smoother feel). The market for Tangaliya has hence become small.
						</p>
						<p>
							Today there are <b>only 15 families</b> in Surendranagar (<i>the birthplace of Tangaliya!</i>) pursuing the craft. A majority of customers are
							international. Small interventions by the National Institute of Fashion Technology (NIFT) and National Institute of
							Design (NID) have had little to no affect.
						</p>
						<p>
							Liyara tries to be interactive as possible and offer the chatters (that's YOU!) a conversational approach, keeping things interesting
							for those even not-so-interested. The goal is to keep the user entertained. It is like speaking to a Tangaliyara expert at your disposal.
						</p>
					</Segment>
				</Grid.Column>
				<Grid.Column width={8}>
					<Header block attached="top">
						<Icon name="microchip"></Icon>
						<Header.Content>
							How Liyara Works?
							<Header.Subheader>Technical Details of Liyara</Header.Subheader>
						</Header.Content>
					</Header>
					<Segment attached="bottom">
						Liyara primarily relies on 3 models for conversations:
						<ol>
							<li>
								<Icon name="facebook" />Meta LLama 3.1, a large-language text generation model to handle most of the conversation flow.
								This is what makes conversations look realistic and lively.
							</li>
							<li>
								<Icon name="code" />xLAM, a function-calling model is used internally to create <i>structured</i> data from your messages.
								This allows Liyara to dynamically alter the state of the conversation. For example, when you tell Liyara <i>'My name is Muqsit'</i>,
								you'll see your name appear in every message. Saying <i>'im actually a male'</i> (when Liyara knows you are a female) will change your
								profile picture to a male avatar.
							</li>
							<li>
								<Icon name="non binary transgender" />A generic gender classification model is used to assume your sex from your name. When you
								tell Liyara your name, you will see your assigned sex under the <Icon name='user' /> profile information section.
							</li>
						</ol>
						What's more? <Icon name="map marker alternate" />Location sharing lets Liyara know the current <Icon name="cloud" />weather in your area.
						You'll be able to view the location and weather ino that Liyara has about you under the <Icon name='user' /> profile information section.
					</Segment>
				</Grid.Column>
			</Grid.Row>
			<Grid.Row divided stackable>
				<Grid.Column>
					<Header block attached="top">
						<Icon name="microchip"></Icon>
						<Header.Content>
							Lets have some fun!
							<Header.Subheader>Some ways to make the conversation more engaging</Header.Subheader>
						</Header.Content>
					</Header>
					<Segment attached="bottom">
						Liyara has a short memory storage. This means you can make Liyara explicitly remember a few items.
						<ul>
							<li>
								<Icon name="non binary transgender" />Tell Liyara 'im actually a boy' / 'im actually a girl' and watch how she changes your avatar.
							</li>
							<li>
								<Icon name="tag" />Tell Liyara your name is actually (insert name here) and watch how she discards your previous name.
							</li>
							<li>
								<Icon name="tag" />Tell Liyara your nickname is (insert nickname here) or you go by (insert nickname here) and watch her remember that!
							</li>
							<li>
								<Icon name="talk" />Say something like <i>talk to me in malay</i> and watch how Liyara respond back in Malay.
							</li>
							<li>
								<Icon name="paint brush" />Ask Liyara to <i>include lots of emojis when talking</i> for a more visually appealing chat! 💬✨
							</li>
						</ul>
					</Segment>
				</Grid.Column>
			</Grid.Row>
		</Grid>
	}

	render() {
		const contentHeight = Math.max(480, this.state.window_height * 0.9)
		return <>
			<SemanticToastContainer />
			{this.renderProfileModal()}
			{this.renderServerInfoModal()}
			<Grid stackable padded="horizontally" stretched>
				<Grid.Row>
					<Grid.Column only="largeScreen" />
				</Grid.Row>
				<Grid.Row>
					<Grid.Column width={4} only="computer">
						{this.renderCardContent(true)}
					</Grid.Column>
					<Grid.Column width={4} only="tablet mobile">
						{this.renderCardContent(false)}
					</Grid.Column>
					<Grid.Column width={12} style={{ height: contentHeight }}>
						<Segment id="chat-window" attached="top" style={{ height: "100%", overflowY: "scroll", background: '#ffffffd9' }}>
							<Container fluid>
								{this.renderChatFlow()}
							</Container>
						</Segment>
						<Segment attached="bottom">
							<Form onSubmit={e => this.onSubmitPrompt(e, this.state.uuid)}>
								<FormGroup widths="equal" style={{ margin: "inherit" }}>
									<FormField width={13} attached="bottom">
										<input id="prompt-field" type="text" name="prompt" placeholder="Enter message" autoCapitalize="off" autoComplete="off" />
									</FormField>
									<FormField control={Button} width={1} color="teal" id="prompt-field-button" icon="paper plane" fluid>
									</FormField>
								</FormGroup>
							</Form>
						</Segment>
					</Grid.Column>
				</Grid.Row>
			</Grid>
			{this.renderInformationText()}
		</>
	}
};
